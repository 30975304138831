import {
  ConfigurationStatuses,
  EntityTypes,
  PERuleCategories,
  PERuleCategoriesAll,
} from '@shared/constants';

import api from '@shared/services/api';

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const LOAN_PRICING_API_URL = '/pe/api/configurations/';
export const MORTGAGE_INSURANCE_API_URL = '/pe/api/miconfigurations/';

import store from '@src/store';

/** Returns api url based on isMiRateCard flag
 *  @returns {String} api url
 */
export function getApiUrl() {
  const isMiRateCard = store?.state?.core?.isMiRateCard;

  return isMiRateCard ? MORTGAGE_INSURANCE_API_URL : LOAN_PRICING_API_URL;
}

/** Returns route changeset details name based on isMiRateCard flag
 *  @returns {String} route name
 */
export function getRouteChangesetDetailsName() {
  const isMiRateCard = store?.state?.core?.isMiRateCard;

  return isMiRateCard
    ? 'mortgage-insurance-rate-card-details'
    : 'pricing-details';
}

/** Returns url updated with filter params when filter is an array.
 *  @param {String}  url is the URL as a string with any params previously added.
 *  @param {String | Array} filter is a filter criteria. Example ('name,version_name').
 *                   Optional parameter.
 *  @param {Boolean} params_exist tells us if there are already params included on the
 *                   url string.
 *  @returns {*}
 */
function getUrlUpdatedForFilterParams(url, filter, params_exist) {
  if (filter && filter.length) {
    let filterParams;
    if (!Array.isArray(filter)) {
      filterParams = new URLSearchParams([['filter', filter]]);
    } else {
      filterParams = new URLSearchParams(filter.map(item => ['filter', item]));
    }
    if (params_exist) {
      url += '&';
    } else if (url.charAt(url.length - 1) !== '?') {
      url += '?';
    }
    url += filterParams.toString();
  }
  return url;
}

/** Returns an Array of Configuration records that include the following properties:
 *  id, tenantId, name, createdBy, createdOn, isPublished,
 *  baseChangesetId, created_by, and published_by.
 *
 *  @param {Object}  tableOptions an Object confirming to the <b-table> component
 *                   property of the same name, that is used to signal the API
 *                   with parameters like 'sortBy', 'sortDesc', 'pageSize', etc.
 *
 *  @param {Boolean} activeOnly set to true to retrieve the currently active
 *                   configuration exclusively (i.e., draft and currently published).
 *
 *  @param {String | Array}  filter is a filter criteria. Example ('name,version_name').
 *                   Optional parameter.
 *
 *  @param {String}  startDate earliest Date to include configurations for.
 *
 *  @param {String}  endDate latest Date to include configurations for.
 *
 *  @param {Boolean} withNamesAndRates flag indicates that names and rates should be also returned in response
 *
 *  @param {Object}  requestOptions options to be passed to the request handler.
 */
export async function getConfigurations(
  tableOptions = {},
  activeOnly = null,
  filter = null,
  startDate = null,
  endDate = null,
  withNamesAndRates = false,
  requestOptions = {},
) {
  let configurations = {};
  const params = { ...tableOptions };
  if (activeOnly !== null) {
    params.activeOnly = activeOnly;
  }
  if (startDate !== null) {
    params.startDate = startDate;
  }
  if (endDate !== null) {
    params.endDate = endDate;
  }
  params.withNamesAndRates = withNamesAndRates;
  let url = api.constructUrl(getApiUrl(), params);
  url = getUrlUpdatedForFilterParams(
    url,
    filter,
    Object.keys(params).length !== 0,
  );

  const response = await api.get(url, requestOptions);
  configurations = response.configurations;
  configurations.forEach(item => {
    item.text = item.name;
  });
  if (response.next) {
    tableOptions.total = configurations.length + 1;
  }
  return configurations;
}

/** Returns an Array of Configuration records that include the following properties:
 *  id, name
 *
 *  @param {Object}  tableOptions an Object confirming to the <b-table> component
 *                   property of the same name, that is used to signal the API
 *                   with parameters like 'sortBy', 'sortDesc', 'pageSize', etc.
 *
 *  @param {String | Array}  filter is a filter criteria. Example ('name,version_name').
 *                   Optional parameter.
 *
 *  @param {String}  startDate earliest Date to include configurations for.
 *
 *  @param {String}  endDate latest Date to include configurations for.
 *
 *  @param {Object}  requestOptions options to be passed to the request handler.
 */
export async function getConfigurationsIdName(
  tableOptions = {},
  filter = '',
  startDate = null,
  endDate = null,
  requestOptions = {},
) {
  const params = { ...tableOptions };
  if (startDate !== null) {
    params.startDate = startDate;
  }
  if (endDate !== null) {
    params.endDate = endDate;
  }
  let url = api.constructUrl('/pe/api/configurations/id-and-name/', params);
  url = getUrlUpdatedForFilterParams(
    url,
    filter,
    Object.keys(params).length !== 0,
  );
  const response = await api.get(url, requestOptions);
  return response.items;
}

/** Returns a single configuration by id
 *  @param {String} id changesetId/configId/configurationId
 *
 *  @returns {Object} a specific configuration object or null if no id was passed in
 */
export async function getConfiguration(id, params = {}) {
  if (!id) return null;

  let url = `${getApiUrl()}${id}`;
  if (params && Object.keys(params).length !== 0) {
    url = api.constructUrl(url, params);
  }
  return await api.get(url);
}

/** Creates new Configuration record
 *  @param {String}  newConfigName name of new config.
 *
 *  @param {String} baseConfigId base config id.
 *
 *  @param {String} newConfigNotes base config notes.
 */
export async function createConfiguration(
  newConfigName,
  baseConfigId,
  newConfigNotes = null,
) {
  return await api.post(getApiUrl(), {
    name: newConfigName,
    id: baseConfigId,
    notes: newConfigNotes,
  });
}

/** Publish a single configuration by id
 *  @param {String} id changesetId/configId/configurationId
 */
export async function publishConfiguration(id) {
  if (!id) return null;

  return api.post(`${getApiUrl()}${id}/publish/`);
}

/** Checks whether a Configuration ID should be treated as Read-only
 *
 *  @param {String} Configuration ID to be checked.
 *
 *  @returns {Boolean} true if Configuration is Read-only. false otherwise.
 */
export async function isConfigurationReadOnly(configurationId) {
  if (!configurationId) {
    throw Error('Configuration ID is needed in order to perform check.');
  }
  const url = api.constructUrl(`/pe/api/configurations/${configurationId}`);
  const config = await api.get(url);
  return config.status !== ConfigurationStatuses.DRAFT;
}

/** Checks whether or not a Product Code has already been used.
 *
 * @param {String} configurationId to check against.
 * @param {String} productCode to check availability for.
 *
 * @returns {Boolean} true if code exists (it's in use), false otherwise.
 */
export async function productCodeExists(configurationId, productCode = null) {
  if (!configurationId || !productCode) {
    throw Error(
      'Configuration ID and Product Code are needed in order to perform check.',
    );
  }
  const params = {
    productCode: productCode,
  };
  const url = api.constructUrl(
    `/pe/api/configurations/${configurationId}/products`,
    params,
  );
  try {
    await api.head(url);
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return false;
    }
    throw error;
  }
  return true;
}

/** Checks whether an Entity name has already been used.
 *
 * @param {String} configurationId to check against.
 * @param {String} entityType to check availability for.
 * @param {String} entityName to check availability for.
 *
 * @returns {Promise<boolean>} true if name is already taken, false otherwise.
 */
export async function entityNameExists(
  configurationId,
  entityType = null,
  entityName = null,
) {
  if (!configurationId || !entityType || !entityName) {
    throw Error(
      'Configuration ID, Entity type and Entity name are needed in order to check name.',
    );
  }
  const payload = {
    entityType: entityType,
    entityName: entityName,
  };
  const url = `/pe/api/configurations/${configurationId}/check_name`;
  const response = await api.postJson(url, payload);
  return response.exists;
}

/** Updates the specified Changeset using the provided payload.
 *
 * @param {String} configurationId for Changeset to update.
 * @param {Object} updatePayload to applied to Configuration.
 *
 * @returns {Object} The received response.
 */
export function updateConfiguration(configurationId, updatePayload) {
  if (!configurationId || !updatePayload) {
    throw Error(
      'Configuration ID, Update Payload are needed in order to update.',
    );
  }
  return api.patch(`${getApiUrl()}${configurationId}`, {
    ...updatePayload,
  });
}

/** Updates the specified Product using the provided payload.
 *
 * @param {String} configurationId for Product to update.
 * @param {String} productId to update.
 * @param {Object} updatePayload to applied to Product.
 *
 * @returns {Object} The received response.
 */
export function updateProduct(configurationId, productId, updatePayload) {
  if (!configurationId || !productId || !updatePayload) {
    throw Error(
      'Configuration ID, Product ID and Update Payload are needed in order to update.',
    );
  }
  return api.patch(`${getApiUrl()}${configurationId}/products`, {
    product_id: productId,
    ...updatePayload,
  });
}

/** Create a new Rule with the provided arguments.
 *
 *  @param {String} configurationId in which Rule exists.
 *  @param {Object} payload - new rule payload.
 *
 *  @returns {Object} The created Rule.
 */
export async function createRule(configurationId, payload) {
  if (!configurationId || !payload) {
    throw Error(
      'Configuration ID, Rule payload are needed in order to create.',
    );
  }

  return api.post(
    `${getApiUrl()}${configurationId}/${EntityTypes.Rule}s`,
    payload,
  );
}

/** Updates the specified Rule using the provided payload.
 *
 * @param {String} configurationId for Product to update.
 * @param {String} ruleId to update.
 * @param {Object} updatePayload to applied to Rule.
 *
 * @returns {Object} The received response.
 */
export function updateRuleEntity(configurationId, ruleId, updatePayload) {
  if (!configurationId || !ruleId || !updatePayload) {
    throw Error(
      'Configuration ID, Rule ID and Update Payload are needed in order to update.',
    );
  }
  return api.patch(`${getApiUrl()}${configurationId}/${EntityTypes.Rule}s`, {
    entity_id: ruleId,
    ...updatePayload,
  });
}

/** Clones a rule.
 *
 * @param {String} configurationId for Product to update.
 * @param {String} ruleId to update.
 * @param {Any} overlay rule overlay
 * @param {String} ownerId rule owner id (if owner isn't current user)
 * @param {String} ownerConfigurationId rule owner configuration id (if owner isn't current user)
 *
 * @returns {Object} The received response.
 */
export async function cloneRule(
  configurationId,
  ruleId,
  overlay = null,
  ownerId = null,
  ownerConfigurationId = null,
) {
  if (!configurationId || !ruleId) {
    throw Error('Configuration ID, Rule ID are needed in order to clone.');
  }
  return api.post(
    `${getApiUrl()}${configurationId}/${EntityTypes.Rule}s/clone`,
    {
      entity_id: ruleId,
      overlay: overlay,
      owner_id: ownerId,
      owner_configuration_id: ownerConfigurationId,
    },
  );
}

/** Deletes the specified Rule.
 *
 * @param {String} configurationId for Product to update.
 * @param {String} ruleId to update.
 *
 * @returns {Object} The received response.
 */
export async function deleteRule(configurationId, ruleId) {
  if (!configurationId || !ruleId) {
    throw Error('Configuration ID, Rule ID are needed in order to delete.');
  }
  return api.delete(`${getApiUrl()}${configurationId}/${EntityTypes.Rule}s`, {
    entity_id: ruleId,
  });
}

/**
 * Update an existing RuleGroup.
 *
 * @param {String} configurationId for RuleGroup to udpate.
 * @param {String} ruleGroupId to update.
 * @param {Object} payload that contains the data that should be updated for the RuleGroup.
 * @returns {Object} The response from the update.
 */
export async function updateRuleGroup(configurationId, ruleGroupId, payload) {
  if (!configurationId || !ruleGroupId || !payload) {
    throw Error(
      'Configuration ID, Rule Group ID and Payload are needed in order to update a rule group.',
    );
  }
  return api.patch(`/pe/api/configurations/${configurationId}/rule-groups`, {
    entity_id: ruleGroupId,
    ...payload,
  });
}

/** Updates the specified Audience(Channel) using the provided payload.
 *
 * @param {String} configurationId for Product to update.
 * @param {String} audienceId to update.
 * @param {Object} updatePayload to applied to Rule.
 *
 * @returns {Object} The received response.
 */
export function updateAudience(configurationId, audienceId, updatePayload) {
  if (!configurationId || !audienceId || !updatePayload) {
    throw Error(
      'Configuration ID, Audience ID and Update Payload are needed in order to update.',
    );
  }
  return api.patch(`/pe/api/configurations/${configurationId}/audiences`, {
    audience_id: audienceId,
    ...updatePayload,
  });
}

/** Returns an array of Products assigned to an Audience
 *  all their attributes as returned by the Micro Service.
 *
 *  @param {String} configurationId in which Audience exists.
 *  @param {String} audienceId to return Products for.
 *
 *  @returns {Array} Array of Products assigned to Audience.
 */
export async function getAudienceProducts(configurationId, audienceId) {
  if (!configurationId || !audienceId) return;
  const response = await api.get(
    `/pe/api/configurations/${configurationId}/audience/${audienceId}/products`,
  );
  return response.products;
}

const CUSTOM_PARAM = 'custom-parameters';
const SYSTEM_PARAM = 'system-parameters';
const GRID = 'grids';

/** Updates the specified Custom Parameter using the provided payload.
 *
 * @param {String} configurationId for Custom Parameter to update.
 * @param {String} customParameterId to update.
 * @param {Object} updatePayload to apply to Custom Parameter.
 *
 * @returns {Object} The received response.
 */
export function updateCustomParameter(
  configurationId,
  customParameterId,
  updatePayload,
) {
  if (!configurationId || !customParameterId || !updatePayload) {
    throw Error(
      'Configuration ID, Custom Parameter ID and Update Payload are needed in order to update.',
    );
  }
  return api.patch(
    `/pe/api/configurations/${configurationId}/${CUSTOM_PARAM}`,
    {
      custom_value_id: customParameterId,
      ...updatePayload,
    },
  );
}

/** Returns rules related to custom value.
 *
 *  @param {String} configurationId in which custom value exists.
 *  @param {String} customValueId to return Rules for.
 *
 *  @returns {Array} Array of Rules.
 */
export async function getCustomValueReferencedRules(
  configurationId,
  customValueId,
) {
  const response = await getCustomValueReferencedItems(
    configurationId,
    customValueId,
    'rulebase',
  );
  return response;
}

async function getCustomValueReferencedItems(
  configurationId,
  customValueId,
  filter,
) {
  if (!configurationId || !customValueId) return;
  return await api.get(
    `/pe/api/configurations/${configurationId}/${CUSTOM_PARAM}/${customValueId}/referencedby/${filter}`,
  );
}

/** Returns rules related to system parameter.
 *
 *  @param {String} configurationId in which custom value exists.
 *  @param {String} systemParameterName to return Rules for.
 *
 *  @returns {Array} Array of Rules.
 */
export async function getSystemParameterReferencedRules(
  configurationId,
  systemParameterName,
) {
  const response = await getSystemParameterReferencedItems(
    configurationId,
    systemParameterName,
    'rulebase',
  );
  return response;
}

async function getSystemParameterReferencedItems(
  configurationId,
  systemParameterName,
  filter,
) {
  if (!configurationId || !systemParameterName) return;
  return await api.get(
    `/pe/api/configurations/${configurationId}/${SYSTEM_PARAM}/${systemParameterName}/referencedby/${filter}`,
  );
}

/** Returns rules related to grids.
 *
 *  @param {String} configurationId in which custom value exists.
 *  @param {String} gridId to return Rules for.
 *
 *  @returns {Array} Array of Rules.
 */
export async function getGridReferencedRules(configurationId, gridId) {
  return await getGridReferencedItems(configurationId, gridId, 'rules');
}

async function getGridReferencedItems(configurationId, gridId, filter) {
  if (!configurationId || !gridId) return;
  return await api.get(
    `/pe/api/configurations/${configurationId}/${GRID}/${gridId}/referencedby/${filter}`,
  );
}
/** Returns an Audience by audience id
 *
 *  @param {String} configurationId in which Audience exists.
 *  @param {String} audienceId to return Audience for.
 *
 *  @returns {Object} The received audience.
 */
export async function getAudience(configurationId, audienceId) {
  const entities = await getEntities(
    configurationId,
    'audiences',
    null,
    audienceId,
  );
  return entities[0];
}

/** Returns an Product by product id
 *
 *  @param {String} configurationId in which Product exists.
 *  @param {String} productId to return Product for.
 *
 *  @returns {Object} The received product.
 */
export async function getProduct(configurationId, productId, params = null) {
  const entities = await getEntities(
    configurationId,
    'products',
    null,
    productId,
    null,
    params,
  );
  return entities[0];
}

/** Returns list of Grids
 *
 *  @param {String} configurationId in which Grid exists.
 *  @param {String} gridName to return Grid for.
 *  @param {String} filter.
 *
 *  @returns {Object} The received Grid.
 */
export async function getGrids(configurationId, gridName, filter) {
  return await getEntities(configurationId, 'grids', filter);
}

/** Returns list of Matrices
 *
 *  @param {String} configurationId in which Matrix exists.
 *  @param {String} filter.
 *
 *  @returns {Object} The received Matrix.
 */
export async function getMatrices(configurationId, filter) {
  return await getEntities(configurationId, 'eligibility-matrices', filter);
}

/** Returns a Grid by Name
 *
 *  @param {String} configurationId in which Grid exists.
 *  @param {String} gridName to return Grid for.
 *
 *  @returns {Object} The received Grid.
 */
export async function getGrid(configurationId, gridName) {
  const entities = await getEntities(configurationId, 'grids', null, gridName);
  return entities[0];
}

/** Returns a Matrix by Name
 *
 *  @param {String} configurationId in which Matrix exists.
 *  @param {String} matrixName to return Grid for.
 *
 *  @returns {Object} The received Grid.
 */
export async function getMatrix(configurationId, matrixName) {
  const entities = await getEntities(
    configurationId,
    'eligibility-matrices',
    null,
    matrixName,
  );
  return entities[0];
}

/** Creates a new Grid with the provided arguments.
 *
 *  @param {String} configurationId in which Grid exists.
 *  @param {String} name - name of new grid.
 *  @param {Array} axes - list of axes.
 *  @param {Array} values - list of values.
 *
 *  @returns {Object} The received Grid.
 */
export function createGrid(configurationId, name, axes, values) {
  if (!configurationId || !name) return;

  return api.post(`${getApiUrl()}${configurationId}/grids`, {
    name: name,
    axes: axes,
    values: values,
  });
}

/** Creates a new Matrix with the provided arguments.
 *
 *  @param {String} configurationId in which Matrix exists.
 *  @param {String} name - name of new matrix.
 *  @param {Array} rules - list of rules.
 *
 *  @returns {Object} The received Grid.
 */
export function createMatrix(configurationId, name, rules) {
  if (!configurationId || !name) return;

  return api.post(
    `/pe/api/configurations/${configurationId}/eligibility-matrices`,
    {
      name: name,
      rules: rules,
      isActive: true,
    },
  );
}

/** Updates an existing Grid with the provided arguments.
 *
 *  @param {String} configurationId in which Grid exists.
 *  @param {String} name - name of new grid.
 *  @param {Array} axes - list of axes.
 *  @param {Array} values - list of values.
 *
 *  @returns {Object} The updated Grid.
 */
export function updateGrid(configurationId, name, axes, values) {
  if (!configurationId || !name) return;

  return api.put(`/pe/api/configurations/${configurationId}/grids`, {
    name: name,
    axes: axes,
    values: values,
  });
}

/** Updates an existing Matrix with the provided arguments.
 *
 *  @param {String} configurationId in which Matrix exists.
 *  @param {String} id - matrix id.
 *  @param {Object} payload - object of parameters.
 *
 *  @returns {Object} The updated Matrix.
 */
export function updateMatrix(configurationId, id, payload) {
  if (!configurationId || !id) return;

  return api.patch(
    `/pe/api/configurations/${configurationId}/eligibility-matrices`,
    {
      entity_id: id,
      ...payload,
    },
  );
}

/** Checks whether a grid exist or not
 *
 *  @param {String} configId - configurationId in which Grid exists
 *  @param {String} gridName - grid name
 *
 *  @returns {Boolean} true - if grid exists, otherwise - false.
 */
export async function isGridExist(configId, gridName) {
  const result = await getGrids(configId, gridName, `name,${gridName}`);
  return !!result.find(o => o.name.toLowerCase() === gridName.toLowerCase());
}

/** Checks whether a matrix exists or not
 *
 *  @param {String} configId - configurationId in which Matrix exists
 *  @param {String} matrixName - matrix name
 *
 *  @returns {Boolean} true - if matrix exists, otherwise - false.
 */
export async function isMatrixExists(configId, matrixName) {
  const result = await getMatrices(configId, `name,${matrixName}`);
  return result.find(o => o.name.toLowerCase() === matrixName.toLowerCase());
}

/** Returns a Rule by ID
 *
 *  @param {String} configurationId in which Rule exists.
 *  @param {String} ruleId to return Rule for.
 *
 *  @returns {Object} The received Rule.
 */
export async function getRule(configurationId, ruleId) {
  const entities = await getEntities(
    configurationId,
    `${EntityTypes.Rule}s`,
    null,
    ruleId,
  );
  return entities[0];
}

/** Returns a Rule Group by ID
 *
 *  @param {String} configurationId in which Rule Group exists.
 *  @param {String} ruleId to return Rule Group for.
 *
 *  @returns {Object} The received Rule Group.
 */
export async function getRuleGroup(configurationId, ruleGroupId) {
  const entities = await getEntities(
    configurationId,
    'rule-groups',
    null,
    ruleGroupId,
  );
  return entities[0];
}

/** Returns an array of Entities for a Configuration ID with
 *  all their attributes as returned by the Micro Service. If
 *  provided pageOptions, returns an object with an array of Entities
 *  and a total count of all results.
 *
 *  @param {String} configurationId for which to return Entities for.
 *  @param {String} entityType to operate with.
 *  @param {String} entityFilter used to filter returned Entities using criteria
 *                               as described in https://pollyex.quip.com/nCnIATubDmQ0
 *  @param {String} entityId used to filter returned Entities by ID.
 *  @param {Object} pageOptions used to specify pagination parameters.
 *  @param {Object} queryParams used to supply query parameters, outside of pagination options
 */
export async function getEntities(
  configurationId,
  entityType,
  entityFilter = null,
  entityId = null,
  pageOptions = null,
  queryParams = null,
) {
  if (!configurationId || !entityType) {
    throw Error('Configuration ID and entityType are required.');
  }
  const params = { ...pageOptions, ...queryParams };
  if (entityFilter) {
    params['entityFilter'] = entityFilter;
  }
  if (entityId) {
    params['entityId'] = entityId;
  }

  let url = `${getApiUrl()}${configurationId}/${entityType}`;
  if (Object.keys(params).length != 0) {
    url = api.constructUrl(url, params);
  }

  const response = await api.get(url);

  // Return pagination data if explicitly provided pagination options
  if (pageOptions) {
    return response;
  }
  return response.entities;
}

/** Returns an array of Audiences for a Configuration ID with
 *  all their attributes as returned by the Micro Service.
 *
 *  @param {String} configurationId for which to return Audiences for.
 *  @param {String} audienceName used to filter returned Audiences by name.
 */
export async function getAudiences(configurationId, audienceName = null) {
  return await getEntities(configurationId, 'audiences', audienceName, null);
}

/** Returns an array of Products for a Configuration ID with
 *  all their attributes as returned by the Micro Service.
 *
 *  @param {String} configurationId for which to return Products for.
 *  @param {String} filter used to filter.
 *  @param {String} productId used to filter returned Product by ID.
 *  @param {Object} pageOptions used to specify pagination parameters.
 *  @param {Object} queryParams used to specify all additional query parameters
 */
export function getProducts(
  configurationId,
  filter = null,
  productId = null,
  pageOptions = null,
  queryParams = null,
) {
  return getEntities(
    configurationId,
    'products',
    filter,
    productId,
    pageOptions,
    queryParams,
  );
}

/** Returns an array of Loan Pricing Products for a Configuration ID.
 *
 * @param {string} configurationId
 * @param {string} filter
 * @returns An array of product ids for the configuration or filter.
 */
export async function getLoanPricingProducts(configurationId, filter) {
  let url = `/pe/api/configurations/${configurationId}/products`;
  if (filter) {
    const params = { entityFilter: filter };
    url = api.constructUrl(url, params);
  }
  const response = await api.get(url);
  return response.entities;
}

/**
 *  This will get an unpaginated list of products for a configuration.  Will return
 *  just an array of product ids with or without a filter.
 * @param {string} configurationId
 * @param {string} filter
 * @returns An array of product ids for the configuration or filter.
 */
export async function getProductIdList(configurationId, filter) {
  let url = `/pe/api/configurations/${configurationId}/products/list`;
  if (filter) {
    const params = { entityFilter: filter };
    url = api.constructUrl(url, params);
  }
  const ids = await api.get(url);
  return ids.map(x => x.id);
}
/**
 *
 * @param {string} configurationId
 * @param {object} payload this will be bulk updates that should be in the following format:
 *                         {productIds: Array,
 *                          updates: {
 *                            isActive: bool,
 *                            isHedged: bool,
 *                            customLockPeriods: object
 *                            }
 *                          }
 */
export async function postBulkUpdateProducts(configurationId, payload) {
  return await api.patch(
    `/pe/api/configurations/${configurationId}/products/bulk-update`,
    payload,
  );
}
/**
 *
 * @param {string} configurationId
 * @param {object} payload used to bulk delete products:
 *                         {
 *                           productIds: Array
 *                         }
 */
export async function bulkDeleteProducts(configurationId, payload) {
  return await api.delete(
    `/pe/api/configurations/${configurationId}/products/bulk-delete`,
    payload,
  );
}

/**
 * Return an Excel file with details of the provided products
 * @param {String} configurationId
 * @param {Array} productIds
 * @returns {Promise<*>}
 */
export async function downloadProductDetails(configurationId, productIds = []) {
  const headers = {
    'Access-Control-Expose-Headers': 'Content-Disposition',
  };
  return await api.postJson(
    `/pe/api/configurations/${configurationId}/products/download`,
    { productIds },
    {
      responseType: 'blob',
      headers: headers,
    },
  );
}

/** Returns an array of Custom Parameters for a Configuration ID with
 *  all their attributes as returned by the Micro Service.
 *
 *  @param {String} configurationId for which to return Custom Parameters for.
 *  @param {String} customParameterName used to filter returned Custom Parameters by name.
 *  @param {String} customParameterId used to filter returned Custom Parameters by ID.
 */
export async function getCustomParameters(
  configurationId,
  customParameterName = null,
  customParameterId = null,
) {
  return getEntities(
    configurationId,
    CUSTOM_PARAM,
    customParameterName,
    customParameterId,
  );
}

/** Returns an Custom Parameter object by Custom Parameter ID
 *
 *  @param {String} configurationId for which to return Custom Parameter for.
 *  @param {String} customParameterId used to filter returned Custom Parameters by ID.
 *
 *  @returns {Object} The received custom parameter.
 */
export async function getCustomParameter(configurationId, customParameterId) {
  const entities = await getEntities(
    configurationId,
    CUSTOM_PARAM,
    null,
    customParameterId,
  );
  return entities[0];
}

const ENTITY_UNIQUENESS_RETRIES = 100;
/** Returns a unique name that hasn't been previously used for an Entity
 *  (i.e. to use when cloning a Product/Audience)
 *
 *  @param {String} configurationId to use.
 *  @param {String} initialName to use as the base of the unique copy name.
 *  @param {String} entityType for which the name is needed.
 */
export async function getUniqueEntityCopyName(
  configurationId,
  initialName,
  entityType,
) {
  if (!configurationId || !entityType) {
    throw Error(
      'Configuration ID and entityType are required for getUniqueEntityCopyName.',
    );
  }
  const url = api.constructUrl(
    `/pe/api/configurations/${configurationId}/check_name`,
    {
      configurationId,
      initialName,
      entityType,
    },
  );
  return api.get(url);
}

/** Returns a unique code that hasn't been previously used for a Product
 *  (i.e. to use when closing a Product)
 *
 *  @param {String} configurationId to use.
 *  @param {String} initialCode to use as the base of the unique copy code.
 */
export async function getUniqueProductCopyCode(configurationId, initialCode) {
  if (!configurationId) {
    throw Error('Configuration ID is required for getUniqueProductCopyCode.');
  }
  let newCode = initialCode + ' (copy)';
  for (let i = 2; i < ENTITY_UNIQUENESS_RETRIES; i++) {
    const codeExists = await productCodeExists(configurationId, newCode);
    if (!codeExists) {
      return newCode;
    }
    newCode = `${initialCode} (copy ${i})`;
  }

  return initialCode + ' (copy)(copy)';
}

/** Returns an array of Rule Groups for a Configuration ID with
 *  all their attributes as returned by the Micro Service.
 *
 *  @param {String} configurationId  for which to return Rule Groups for.
 *  @param {String} ruleGroupId      used to filter returned Rule Groups by ID.
 */
export async function getRuleGroups(configurationId, ruleGroupId = null) {
  return getEntities(configurationId, 'rule-groups', ruleGroupId);
}

/** Returns an array of Rules for a Configuration ID with
 *  all their attributes as returned by the Micro Service.
 *
 *  @param {String} configurationId for which to return Rules for.
 *  @param {String} filter.
 *  @param {String} ruleId used to filter returned Rule by ID.
 *  @param {Object} pageOptions pagination options
 */
export async function getRules(
  configurationId,
  filter = null,
  ruleId = null,
  pageOptions = null,
) {
  const groups = await getRuleGroups(configurationId);
  const response = await getEntities(
    configurationId,
    `${EntityTypes.Rule}s`,
    filter,
    ruleId,
    pageOptions,
  );

  let sortedRules = [];
  if (response?.entities?.length) {
    sortedRules = response.entities;
  } else if (response?.length) {
    sortedRules = response;
  }

  if (!pageOptions) {
    sortedRules = sortedRules.sort((x, y) =>
      x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1,
    );
  }

  const parsedRules = {
    active: [],
    categories: {},
    all: [],
    groups: groups,
    groupsMap: {},
  };
  const groupsMap = parsedRules.groupsMap;
  for (const group of groups) {
    groupsMap[group.id] = {
      name: group.name,
      rules: new Set(),
    };
  }
  parsedRules.all = sortedRules;
  const allRules = parsedRules.all;

  parsedRules.categories[PERuleCategoriesAll] = parsedRules.all;
  for (const rule of allRules) {
    // Add reference for rule in 'active' key
    if (rule.isActive) {
      parsedRules.active.push(rule);
    }
    // Add reference for rule in corresponding category
    let category = rule.category;

    // on frontend Eligibility and EligibilityMatrix is the same category.
    // we have to merge it in one category - Eligibility
    rule.displayCategory = rule.category;
    if (category === PERuleCategories.EligibilityMatrix) {
      category = PERuleCategories.Eligibility;
      rule.displayCategory = PERuleCategories.Eligibility;
    }
    let ruleCategory = parsedRules.categories[category];
    if (!ruleCategory) {
      parsedRules.categories[category] = [];
      ruleCategory = parsedRules.categories[category];
    }
    ruleCategory.push(rule);
    // Place rule reference in corresponding groupsMap section
    for (const tag of rule.tagIds) {
      if (groupsMap[tag]) groupsMap[tag].rules.add(rule);
    }
  }

  if (response.entities) {
    return {
      ...response,
      ...parsedRules,
    };
  } else {
    return parsedRules;
  }
}

/** Returns an array of Mortgage Insurance Rules for a Configuration ID with
 *  all their attributes as returned by the Micro Service.
 *
 *  @param {String} configurationId for which to return Rules for.
 *  @param {String} filter.
 *  @param {String} ruleId used to filter returned Rule by ID.
 *  @param {Object} pageOptions pagination options
 */
export async function getMortgageInsuranceRules(
  configurationId,
  filter = null,
  ruleId = null,
  pageOptions = null,
) {
  const products = await getProducts(configurationId);
  const response = await getEntities(
    configurationId,
    `${EntityTypes.Rule}s`,
    filter,
    ruleId,
    pageOptions,
  );

  let sortedRules = [];
  if (response?.entities?.length) {
    sortedRules = response.entities;
  } else if (response?.length) {
    sortedRules = response;
  }

  if (!pageOptions) {
    sortedRules = sortedRules.sort((x, y) =>
      x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1,
    );
  }

  const parsedRules = {
    active: [],
    categories: {},
    all: [],
    products,
    productsMap: {},
  };
  const productsMap = parsedRules.productsMap;
  for (const product of products) {
    productsMap[product.id] = {
      name: product.name,
      rules: new Set(),
    };
  }
  parsedRules.all = sortedRules;
  const allRules = parsedRules.all;

  parsedRules.categories[PERuleCategoriesAll] = parsedRules.all;
  for (const rule of allRules) {
    // Add reference for rule in 'active' key
    if (rule.isActive) {
      parsedRules.active.push(rule);
    }
    // Add reference for rule in corresponding mortgage insurance rule category
    const category = rule.category;

    rule.displayCategory = category;
    if (!parsedRules.categories[category]) {
      parsedRules.categories[category] = [];
    }
    parsedRules.categories[category].push(rule);
    // Place rule reference in corresponding productsMap section
    for (const productId of rule.mortgageInsuranceProductIds) {
      if (productsMap[productId]) productsMap[productId].rules.add(rule);
    }
  }

  if (response.entities) {
    return {
      ...response,
      ...parsedRules,
    };
  } else {
    return parsedRules;
  }
}

/** Returns an array of Mortgage Insurance Providers for a Configuration ID with
 *  all their attributes as returned by the Micro Service.
 *
 *  @param {String} configurationId for which to return Rules for.
 */
export async function getMortgageInsuranceProviders(configurationId) {
  return await api.get(
    `${MORTGAGE_INSURANCE_API_URL}${configurationId}/${EntityTypes.Provider}s`,
  );
}

/** Patches Mortgage Insurance Providers for a Configuration ID
 *
 *  @param {String} configurationId for which to return Rules for.
 *  @param {object} payload this will be bulk updates that should be in the following format:
 * {
 *   "MationalMI": true,
 *   "MGIC": true,
 *   "ArchMI": true,
 *   "Radian": true,
 *   "Enact": true,
 *   "Essent": true
 * }
 */
export async function patchMortgageInsuranceProviders(
  configurationId,
  payload,
) {
  return await api.patch(
    `${MORTGAGE_INSURANCE_API_URL}${configurationId}/${EntityTypes.Provider}s`,
    payload,
  );
}
/** Returns a dictionary with Rule enumerations for a version ID, it includes
 *  the type names and enumeration names as expected by the Micro Service
 *  as well as an integer value that matches the corresponding Exchange enum.
 *
 *  @param {String} versionId for which to return Rule enums for.
 */
export async function getRuleEnums(versionId, byName = false) {
  if (!versionId) return;
  const response = await api.get(
    `/pe/api/configurations/${versionId}/rule-enums${byName ? '-by-name' : ''}`,
  );
  return response.rule_enums;
}

/** Returns an array of investors, each with an array of date/times the
 *  investors' base rates were made available, and their IDs.
 *
 *  @param {String} configurationId for which to return available base rates.
 */
export async function getAvailableSourcePricing(configurationId) {
  if (!configurationId) return;
  const response = await api.get(
    `/pe/api/configurations/${configurationId}/available-source-pricing`,
  );
  return response.available_source_pricing;
}

/** Returns an array of investors that could have been used to generate the base
 *  rates, each with a possibly null date/time indicating when the investor's
 *  rates were published. A null date/time indicates that the investor's rates
 *  were excluded from the calculation of the base rates.
 *
 *  @param {String} configurationId for which to return investor rates used.
 *  @param {Boolean} returnEmptyRates whether or not return product source rates that have not been included
 *    in the pricing, but are referenced in the product
 */
export async function getInvestorRatesUsed(
  configurationId,
  returnEmptyRates = true,
) {
  if (!configurationId) return;
  const url = api.constructUrl(
    `/pe/api/configurations/${configurationId}/investor-rates-used`,
    { returnEmptyRates: returnEmptyRates },
  );
  const response = await api.get(url);
  return response.source_pricing_used;
}

/** Returns an array of partnered investors and the products they are offering
 *  to buy for a particular seller.
 */
export async function getAvailableSourceProducts() {
  const response = await api.get('/pe/api/available-source-products');
  return response.available_source_products;
}

/** Gets available investors products **/
export function getAvailableInvestorsProducts() {
  return api.get('/pe/api/available-investor-products');
}

/** Gets updated investor logic **/
export async function getUpdatedInvestorLogic(changesetId, requestOptions) {
  return api.get(
    `/pe/api/updated-investor-logic/${changesetId}`,
    requestOptions,
  );
}

/** Updates investor logic **/
export async function updateInheritedItems(
  changesetId,
  update_inherited_items = false,
  update_dynamic_pricing = false,
) {
  return api.put(
    `/pe/api/updated-investor-logic/${changesetId}?update_inherited_items=${update_inherited_items}&update_dynamic_pricing=${update_dynamic_pricing}`,
  );
}

/** Gets all the inherited rules for the product **/
export function getInheritedItems(configurationId, productId, options = null) {
  return api.get(
    `/pe/api/configurations/${configurationId}/product/${productId}`,
    options,
  );
}

/** Gets all investor rules for dynamic product **/
export function getDynamicRules(configurationId, productId) {
  return api.get(
    `/pe/api/configurations/${configurationId}/products/${productId}/dynamic-rules`,
  );
}

/** Deletes all inherited rules for the product. **/
export function deleteInheritedItems(configurationId, productId) {
  return api.delete(
    `/pe/api/configurations/${configurationId}/product/${productId}`,
  );
}

/** Imports and inherits all rules related to the investor product. **/
export async function importInheritedItems(
  configurationId,
  productId,
  investorId,
  productCode,
) {
  await api.put(
    `/pe/api/configurations/${configurationId}/product/${productId}`,
    {
      investor: investorId,
      code: productCode,
    },
  );
}

/** Gets a products inherited items overlay **/
export function getInheritedItemsOverlay(
  configurationId,
  productId,
  inheritedItemId,
) {
  return api.get(
    `/pe/api/configurations/${configurationId}/product/${productId}/inherited-item/${inheritedItemId}/overlay`,
  );
}

/** Deletes a products inherited items overlay **/
export function deleteInheritedItemsOverlay(
  configurationId,
  productId,
  inheritedItemId,
) {
  return api.delete(
    `/pe/api/configurations/${configurationId}/product/${productId}/inherited-item/${inheritedItemId}/overlay`,
  );
}

/** Updates a products inherited items overlay **/
export async function updateInheritedItemsOverlay(
  configurationId,
  productId,
  inheritedItemId,
  updatePayload,
) {
  return api.put(
    `/pe/api/configurations/${configurationId}/product/${productId}/inherited-item/${inheritedItemId}/overlay`,
    {
      ...updatePayload,
    },
  );
}

/** Gets a products inherited item grid **/
export function getInheritedItemGrid(
  configurationId,
  productId,
  gridName,
  investorChangesetId = null,
  investorTenantId = null,
) {
  const encodedGridName = encodeURIComponent(gridName);
  let url = `/pe/api/configurations/${configurationId}/product/${productId}/inherited/grid?gridName=${encodedGridName}`;
  if (investorTenantId && investorChangesetId) {
    // it's a scenario for dynamic pricing
    url = `${url}&investorChangesetId=${investorChangesetId}&investorTenantId=${investorTenantId}`;
  }
  return api.get(url);
}

/** Gets a products inherited item matrix **/
export function getInheritedItemMatrix(
  configurationId,
  productId,
  matrixName,
  investorChangesetId = null,
  investorTenantId = null,
) {
  const encodedMatrixName = encodeURIComponent(matrixName);
  let url = `/pe/api/configurations/${configurationId}/product/${productId}/inherited/matrix?matrixName=${encodedMatrixName}`;
  if (investorTenantId && investorChangesetId) {
    // it's a scenario for dynamic pricing
    url = `${url}&investorChangesetId=${investorChangesetId}&investorTenantId=${investorTenantId}`;
  }
  return api.get(url);
}

/** Get Products details. **/
export function getProductsDetails(configurationId, entityFilter = null) {
  const params = {};
  if (entityFilter) {
    params['entityFilter'] = entityFilter;
  }

  let url = `/pe/api/configurations/${configurationId}/products/details`;
  if (Object.keys(params).length !== 0) {
    url = api.constructUrl(url, params);
  }

  return api.get(url);
}

export function getDynamicInheritedItems(
  dynamicRules,
  availableInvestorProducts,
) {
  const rules = [];
  const investorIdNameMap = availableInvestorProducts.map(v => ({
    id: String(v.investor_id),
    name: String(v.investor_name),
    products: v.active_products,
    changesetId: v.changeset_id,
  }));

  for (const ruleDetails of Object.values(dynamicRules)) {
    const find = investorIdNameMap.find(
      v => v.id === String(ruleDetails.investorId),
    );
    if (!find) return rules;

    const newRules = [...ruleDetails.rules];
    for (const r of newRules) {
      r.investor = find.name;
      r.productName = find.products.find(
        v => String(v.id) === String(ruleDetails.productId),
      )?.name;
      r.changesetId = find.changesetId;
      r.productCode = ruleDetails.productCode;
      r.tenantId = ruleDetails.investorId;
      rules.push(r);
    }
  }
  return { rules: rules };
}

function hasUpdatedValues(updates) {
  return updates && Object.values(updates).some(e => e.length);
}

/** Has source any investor updates or investor deleted products **/
export function hasAnyInvestorUpdates(source) {
  const hasUpdates = hasUpdatedValues(source?.investorUpdates);
  const hasDeleted = hasUpdatedValues(source?.deletedInvestorReferences);
  return hasUpdates || hasDeleted;
}

/** Get Dynamic Pricing details. **/
export function getDynamicPricingDetails(
  requestId,
  productId,
  lockPeriod,
  rate,
) {
  if (!requestId || !productId || !lockPeriod || !rate) {
    throw Error(
      'Request ID, Product ID, lock period, rate are needed in order to get dynamic pricing details.',
    );
  }

  return api.get(
    `/pe/api/request/${requestId}/dynamic-pricing-detail/${productId}/${lockPeriod}/${rate}`,
  );
}

/** Creates a new Entity with the provided arguments.
 *
 *  @param {String} configurationId in which Value Grouping exists.
 *  @param {String} entityType new entity type.
 *  @param {Object} config - json config on the new Entity.
 *
 *  @returns {Object} The received Entity.
 */
export function createEntity(configurationId, entityType, config) {
  if (!configurationId || !entityType || !config)
    throw Error(
      'configurationId, entityType, config are needed in order to get result.',
    );

  return api.post(
    `/pe/api/configurations/${configurationId}/${entityType}`,
    config,
  );
}

/** Updates the specified Entity using the provided payload.
 *
 * @param {String} configurationId for Entity to update.
 * @param {String} entityId to update.
 * @param {String} entityType new entity type.
 * @param {Object} updatePayload to apply to Entity.
 *
 * @returns {Object} The received response.
 */
export function updateEntity(
  configurationId,
  entityType,
  entityId,
  updatePayload,
) {
  if (!configurationId || !entityId || !entityType || !updatePayload)
    throw Error(
      'configurationId, entityId, entityType, updatePayload are needed in order to get result.',
    );

  return api.patch(`/pe/api/configurations/${configurationId}/${entityType}`, {
    entity_id: entityId,
    ...updatePayload,
  });
}

/** Deletes the specified Entity.
 *
 * @param {String} configurationId for Entity to update.
 * @param {String} entityId to delete.
 *
 * @returns {Object} The received response.
 */
export async function deleteEntity(configurationId, entityType, entityId) {
  if (!configurationId || !entityType || !entityId)
    throw Error(
      'configurationId, entityId, entityType are needed in order to get result.',
    );

  return api.delete(`/pe/api/configurations/${configurationId}/${entityType}`, {
    entity_id: entityId,
  });
}

/** Get State related Counties.
 *
 * @param {String} stateCode state.
 *
 * @returns {List} The received counties.
 */
export async function getCounties(stateCode) {
  if (!stateCode) throw Error('stateCode are needed in order to get result.');

  return api.get(`/api/zip_codes/counties/${stateCode}/`);
}

/** Get County related State.
 *
 * @param {List} counties county.
 *
 * @returns {List} The received state.
 */
export async function getStatesForCounties(counties) {
  if (!counties) throw Error('counties are needed in order to get result.');

  return api.post(`/api/zip_codes/states/`, counties);
}

export async function getPriceExceptionReasons() {
  return api.get('/api/price_exception_reasons');
}

/** Returns the Entities (Rule, Eligibility Matrix, Grid) where search string were used in Structures.
 *
 * @param {string} configId configurationId.
 *
 * @param {string} searchString search string.
 *
 * @param {?string} typeNameFilter filter entities string.
 */
export async function getUsagesInRules(
  configId,
  searchString,
  typeNameFilter = null,
) {
  if (searchString == null)
    throw Error('searchString are needed in order to get result.');

  let url = `/pe/api/configurations/${configId}/rules/find-usages-of/${searchString}`;
  if (typeNameFilter) {
    url += `?typeNameFilter=${typeNameFilter}`;
  }
  return api.get(url);
}

/** Returns the Audit Entries related to the removal of the partnership.
 *
 * @param {string} configId configurationId.
 */
export async function getRemovedPartnershipsAuditEntries(configId) {
  return api.get(`/pe/api/configurations/${configId}/partner-audit-entries`);
}
/** Set Lock Request status Pending and go through LOS.
 *
 * @param {integer} lockRequestPk id of the lock request.
 * @param {integer} totalLoanAmount total amount of the loan.
 * @param {boolean} ignorePolicies Should we ignore policies as a LD or higher user
 *
 */
export async function setStatusToPending(
  lockRequestPk,
  totalLoanAmount,
  ignorePolicies,
) {
  const payload = {
    lockRequestPk,
    totalLoanAmount,
    ignorePolicies,
  };
  return await api.patch(`/pe/api/lock-request/if-pending/`, payload);
}

/** Returns Los Custom Credit for the los lock request.
 *
 * @param {Number} lockRequestId id of the lock request.
 *
 */
export function getLockRequestCustomCredit(lockRequestId) {
  return api.get(`/pe/api/lock-requests/${lockRequestId}/custom-credit/`);
}

/** Create Los Custom Credit for the los lock request.
 *
 * @param {Number} lockRequestId id of the lock request.
 * @param {Object} customCreditPayload custom credit data.
 *
 */
export function createLockRequestCustomCredit(
  lockRequestId,
  customCreditPayload,
) {
  return api.post(
    `/pe/api/lock-requests/${lockRequestId}/custom-credit/`,
    customCreditPayload,
  );
}
