import _get from 'lodash/get';

import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import {
  filterOnAllObjectValues,
  sortByLastModifiedDesc,
} from '@pe/functions/helpers.js';
import {
  getLockDeskLoans,
  getActionRequiredCount,
  getActionRequiredLoans,
  getActionRequiredSummary,
} from '@pe/services/loans.js';

import {
  SET_SEARCH_TEXT,
  SET_LOANS,
  SET_LOADING,
  SET_ORG_ID,
  SET_ACTION_REQUIRED_SUMMARY_LOADING,
  SET_ACTION_REQUIRED_LOANS_LOADING,
  SET_ACTION_REQUIRED_COUNT,
  SET_ACTION_REQUIRED_LOANS,
  SET_ACTION_REQUIRED_SUMMARY,
  SET_AUTO_CANCELLED_COUNT,
} from './mutationTypes.js';

const initialState = {
  searchText: null,
  loading: false,
  loans: [],
  orgId: null,
  actionRequiredSummaryLoading: false,
  actionRequiredLoansLoading: false,
  actionRequiredLoans: { loans: [], total: 0 },
  actionRequiredCount: 0,
  actionRequiredSummary: [
    {
      action: 0,
      dueToday: 0,
      dueTomorrow: 0,
      pastDue: 0,
      dueFuture: [],
    },
  ],
  autoCancelledRequestsCount: 0,
};

const getters = {
  loading: state => state.loading,
  searchText: state => state.searchText,
  loans: state => state.loans,
  actionRequiredLoans: state => state.actionRequiredLoans,
  orgId: state => state.orgId,
  filteredLoans: state => {
    if (!state.searchText) {
      return state.loans.sort(sortByLastModifiedDesc);
    } else {
      return state.loans
        .filter(loan => filterOnAllObjectValues(loan, state.searchText))
        .sort(sortByLastModifiedDesc);
    }
  },
  actionRequiredSummaryLoading: state => state.actionRequiredSummaryLoading,
  actionRequiredLoansLoading: state => state.actionRequiredLoansLoading,
  actionRequiredCount: state => state.actionRequiredCount,
  actionRequiredSummary: state =>
    state.actionRequiredSummary.filter(
      action =>
        _get(action, 'dueFuture.dates', []).length ||
        action.dueToday ||
        action.dueTomorrow ||
        action.pastDue,
    ),
  autoCancelledRequestsCount: state => state.autoCancelledRequestsCount,
};

const mutations = {
  [SET_SEARCH_TEXT](state, searchText) {
    state.searchText = searchText;
  },
  [SET_LOANS](state, loans) {
    state.loans = loans;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_ORG_ID](state, orgId) {
    state.orgId = orgId;
  },
  [SET_ACTION_REQUIRED_SUMMARY_LOADING](state, loading) {
    state.actionRequiredSummaryLoading = loading;
  },
  [SET_ACTION_REQUIRED_LOANS_LOADING](state, loading) {
    state.actionRequiredLoansLoading = loading;
  },
  [SET_ACTION_REQUIRED_COUNT](state, count) {
    state.actionRequiredCount = count;
  },
  [SET_ACTION_REQUIRED_LOANS](state, items) {
    state.actionRequiredLoans = items;
  },
  [SET_ACTION_REQUIRED_SUMMARY](state, summary) {
    state.actionRequiredSummary = summary;
  },
  [SET_AUTO_CANCELLED_COUNT](state, count) {
    state.autoCancelledRequestsCount = count;
  },
};

const actions = {
  setSearchText({ commit }, searchText) {
    if (searchText == null || typeof searchText !== 'string') {
      commit(SET_SEARCH_TEXT, '');
      return;
    }
    const cleanedText = searchText.trim();
    commit(SET_SEARCH_TEXT, cleanedText);
  },
  setLoans({ commit }, loans) {
    commit(SET_LOANS, loans);
  },
  setOrgId({ commit }, orgId) {
    commit(SET_ORG_ID, orgId);
  },
  async fetchLoans({ commit }) {
    commit(SET_LOADING, true);
    commit(SET_LOANS, []);
    commit(SET_ORG_ID, '');

    try {
      const result = await getLockDeskLoans();

      if (!result) return;

      commit(SET_LOANS, result.items);
      commit(SET_ORG_ID, String(result.org_id));
    } catch (error) {
      onErrorHandler(error, 'pe-lock-desk-get-loans');
    } finally {
      commit(SET_LOADING, false);
    }
  },
  setLoanInPipeline({ commit }, { loan, orgId }) {
    commit(SET_LOADING, true);
    commit(SET_LOANS, []);
    commit(SET_ORG_ID, '');

    try {
      if (!loan) return;
      const loans = [loan];

      commit(SET_LOANS, loans);
      commit(SET_ORG_ID, String(orgId));
    } catch (error) {
      onErrorHandler(error, 'pe-lock-desk-set-loan-in-pipeline');
    } finally {
      commit(SET_LOADING, false);
    }
  },
  setActionRequiredSummaryLoading({ commit }, loading) {
    commit(SET_ACTION_REQUIRED_SUMMARY_LOADING, loading);
  },
  async fetchActionRequiredCount({ commit }) {
    try {
      commit(SET_ACTION_REQUIRED_COUNT, 0);
      commit(SET_ACTION_REQUIRED_COUNT, 0);
      const response = await getActionRequiredCount();

      commit(SET_ACTION_REQUIRED_COUNT, response.actionRequired || 0);
    } catch (error) {
      onErrorHandler(error, 'pe-lock-desk-get-action-required-count');
    }
  },
  async fetchActionRequiredCountPolling({ commit, state, dispatch }) {
    try {
      const response = await getActionRequiredCount();

      //if our polling sees a diff amount of required loans, lets go get the new summary as well.
      if (
        response.actionRequired !== state.actionRequiredCount &&
        !state.actionRequiredSummaryLoading
      ) {
        dispatch('fetchActionRequiredSummary');
      }

      commit(SET_ACTION_REQUIRED_COUNT, response.actionRequired);
    } catch (error) {
      onErrorHandler(error, 'pe-lock-desk-get-action-required-count');
    }
  },
  async fetchActionRequiredLoans({ commit }, { tableOptions, search }) {
    try {
      commit(SET_ACTION_REQUIRED_LOANS_LOADING, true);
      commit(SET_ACTION_REQUIRED_LOANS, { loans: [], total: 0 });

      const options = {
        sort_by: tableOptions.sortBy,
        page: tableOptions.pageIndex || 1,
        per_page: tableOptions.pageSize,
        sort_desc: tableOptions.sortDesc,
        search: search || '',
      };

      const response = await getActionRequiredLoans(options);

      if (!response[1]) return;

      commit(SET_ACTION_REQUIRED_LOANS, {
        loans: response[0] || [],
        total: response[1] || 0,
      });
    } catch (error) {
      onErrorHandler(error, 'pe-lock-desk-get-action-required-loans');
    } finally {
      commit(SET_ACTION_REQUIRED_LOANS_LOADING, false);
    }
  },
  async fetchActionRequiredSummary({ commit }) {
    try {
      commit(SET_ACTION_REQUIRED_SUMMARY_LOADING, true);
      commit(SET_ACTION_REQUIRED_SUMMARY, []);
      const response = await getActionRequiredSummary();

      commit(SET_ACTION_REQUIRED_SUMMARY, response.summary);
      commit(SET_AUTO_CANCELLED_COUNT, response.cancelledTodayCount);
    } catch (error) {
      onErrorHandler(error, 'pe-lock-desk-get-action-required-summary');
    } finally {
      commit(SET_ACTION_REQUIRED_SUMMARY_LOADING, false);
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
