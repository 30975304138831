import { createRateAlert, updateRateAlert } from '@pe/services/rateAlerts.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { showSuccessMessage } from '@shared/utils/notifier.js';

const initialSearchCriteria = {
  lockPeriod: null,
  loanTypes: [],
  fixedPrograms: [],
  armPrograms: [],
  includeInterestOnlyProducts: false,
};

const initialRateAlert = {
  id: null,
  name: '',
  receiverEmail: '',
  losLoanId: '',
  peRequestId: '',
  maxRate: null,
  minPrice: null,
  expiresAt: null,
  notes: '',
  loanAmount: null,
  borrowerName: '',
};

const initialState = {
  configModal: {
    isOpen: false,
    isSaving: false,
    rateAlertData: { ...initialRateAlert },
    searchCriteria: { ...initialSearchCriteria },
  },
};

const getters = {
  searchCriteria: state => state.configModal.searchCriteria,
};

const mutations = {
  setIsConfigModalOpen(state, isOpen) {
    state.configModal = { ...state.configModal, isOpen };
  },
  setIsConfigModalSaving(state, isSaving) {
    state.configModal = { ...state.configModal, isSaving };
  },
  setConfigModalRateAlertData(state, rateAlertData) {
    state.configModal.rateAlertData = {
      ...initialRateAlert,
      ...rateAlertData,
    };
  },
  setConfigModalSearchCriteria(state, searchCriteria) {
    state.configModal.searchCriteria = {
      ...state.configModal.searchCriteria,
      ...searchCriteria,
    };
  },
  resetConfigModal(state) {
    state.configModal = { ...initialState.configModal };
  },
};

const actions = {
  closeConfigModal({ commit }) {
    commit('setIsConfigModalOpen', false);
    commit('resetConfigModal');
  },
  async createOrUpdateRateAlert({ commit, rootGetters, state, dispatch }) {
    commit('setIsConfigModalSaving', true);
    try {
      const isUpdate = Boolean(state.configModal.rateAlertData.id);
      const createOrUpdate = isUpdate ? updateRateAlert : createRateAlert;
      const orgId = rootGetters['organizations/currentOrganizationId'];

      const response = await createOrUpdate(
        orgId,
        state.configModal.rateAlertData,
      );

      commit('setConfigModalRateAlertData', response);
      showSuccessMessage(`Rate alert ${isUpdate ? 'updated' : 'created'}.`);
    } catch (error) {
      onErrorHandler(error, 'rateAlert');
    } finally {
      commit('setIsConfigModalSaving', false);
      dispatch('closeConfigModal');
    }
  },
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions,
};
