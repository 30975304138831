<template>
  <v-select
    ref="pxSelect"
    v-bind="$attrs"
    :append-to-body="true"
    :calculate-position="calculatePosition"
    :class="{
      'px-select': true,
      'px-select--plain-text': plaintext,
      'px-select__invalid': invalid,
    }"
    :clearable="clearable"
    :disabled="disabled || plaintext"
    :label="label"
    :options="options"
    :placeholder="placeholder"
    :reduce="reduce"
    :select-on-tab="true"
    :value="value"
    @input="input($event)"
    @search:focus="onFocus($event)"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot v-bind="scope" :name="slot" />
    </template>
    <template #no-options="{ searching }">
      <template v-if="searching">
        <em>No matching options</em>
        .
      </template>
    </template>
    <template #footer>
      <div v-if="invalid" class="message message--warning">
        {{ invalidMessage || 'Invalid Selection' }}
      </div>
      <div v-else-if="infoMessage" class="message message--info">
        {{ infoMessage }}
      </div>
    </template>
  </v-select>
</template>

<script>
function defaultReduce(item) {
  if (typeof item === 'object' && item !== null) {
    return item.value;
  }
  return item;
}

export default {
  name: 'PxSelect',
  props: {
    label: { type: String, default: 'label' },
    reduce: { type: Function, default: defaultReduce },
    options: { type: Array, default: () => [] },
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: null,
    },
    placeholder: {
      type: String,
      default: 'select value',
    },
    disabled: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false },
    fitDropdownToContent: { type: Boolean, default: false },
    dropdownStyles: { type: Object, default: () => ({}) },
    invalid: { type: Boolean, default: false },
    invalidMessage: { type: String, default: null },
    infoMessage: { type: String, default: null },
    clearable: { type: Boolean, default: false },
  },
  methods: {
    input(event) {
      this.$emit('input', event);
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    makeFocused() {
      this.$refs.pxSelect.$refs.search.focus();
    },
    calculatePosition(dropdownList, component, { width, top, left }) {
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = this.fitDropdownToContent ? 'auto' : width;

      if (this.fitDropdownToContent) {
        dropdownList.classList.add('px-select--fit-dropdown');
      }

      Object.keys(this.dropdownStyles).forEach(
        key => (dropdownList.style[key] = this.dropdownStyles[key]),
      );
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';

.px-select {
  border-bottom: solid 2px $border-grey;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  font-size: 1.077rem;
  width: 100%;

  &.px-select--plain-text {
    border-bottom: transparent;

    .vs__actions:before {
      content: none;
    }

    &.vs--disabled {
      .vs__search,
      .vs__dropdown-toggle,
      .vs__open-indicator,
      .vs__selected {
        background: transparent;
        cursor: default;
      }
    }
  }

  &.vs--open {
    border-color: $polly-light-blue;
  }

  &__invalid,
  &__invalid.vs--open {
    border-bottom-color: $polly-orange;
  }

  .vs__search {
    color: $darker-gray;
    margin: 0;
    padding: 0;
    height: 30px;
  }

  .vs__selected {
    color: $default-text;
    height: 2rem;
    margin-bottom: 2px;
    margin-top: 2px;
    padding: 0;
  }

  .vs__selected-options {
    flex-wrap: nowrap;
  }

  .vs__actions {
    width: 1.5rem;
    padding: 0;

    &:before {
      font-family: $font-awesome-family;
      font: $icon-font;
      font-size: 1rem;
      font-weight: 400;
      color: $polly-light-blue;
      content: $arrow-down-code;
    }
  }

  &.vs--open {
    .vs__actions:before {
      content: $arrow-up-code;
    }
  }

  .vs__dropdown-toggle {
    border-width: 0;
    cursor: pointer;
    padding: 0;
  }

  .vs__open-indicator {
    path {
      display: none;
    }
  }
}

.px-select--fit-dropdown {
  &.vs__dropdown-menu {
    max-width: 600px;
  }

  .vs__dropdown-option {
    width: 100%;
    white-space: normal;
  }
}

.vs__dropdown-menu {
  border-width: 0;
  border-top: solid 2px $polly-light-blue;
  box-shadow: $polly-default-shadow;
  max-height: 16.5rem;
  padding: 0;
  z-index: 1050; // px-3194 & px-3741 Temporary to allow px-select to work inside modals

  .vs__dropdown-option {
    color: $darkest-gray;
    text-wrap: wrap;
  }

  .vs__dropdown-option--highlight {
    background-color: $polly-light-blue;
    color: white;
  }

  .vs__no-options {
    color: $darkest-gray;
  }
}
</style>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.message {
  position: absolute;
  right: 0;
  bottom: -18px;
  font-size: 11px;

  &--info {
    color: $gray;
  }

  &--warning {
    color: $polly-orange;
  }
}
</style>
