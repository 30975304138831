import api from '@shared/services/api';

/**
 * Get All Counties.
 * @returns {List} Counties.
 */
export async function getAllCounties() {
  return api.get(`/geo/api/counties/`);
}

/**
 * Get Specific County by Full County FIPS code.
 * @param {string} countyFipsCode
 * @returns {Object} StateCountyInfo object.
 */
export async function getCountyInfoByFipsCode(countyFipsCode) {
  if (!countyFipsCode) return null;
  if (countyFipsCode.length !== 5) return null;

  return api.get(`/geo/api/counties/${countyFipsCode}/`);
}
