export const CLEAR_RULE_DETAILS = 'CLEAR_RULE_DETAILS';
export const SET_CLAMP_CATEGORY = 'SET_CLAMP_CATEGORY';
export const SET_IS_INVALID = 'SET_IS_INVALID';
export const SET_IS_INVALID_NAME = 'SET_IS_INVALID_NAME';
export const SET_IS_NEW_ITEM = 'SET_IS_NEW_ITEM';
export const SET_IS_RULE_SAVING = 'SET_IS_RULE_SAVING';
export const SET_IS_READ_ONLY = 'SET_IS_READ_ONLY';
export const SET_IS_UPDATE_NEEDED = 'SET_IS_UPDATE_NEEDED';
export const SET_RULE = 'SET_RULE';
export const SET_TARGET = 'SET_TARGET';
export const SET_UPDATED_GRIDS = 'SET_UPDATED_GRIDS';
export const SET_MIN_STRUCTURE = 'SET_MIN_STRUCTURE';
export const SET_MAX_STRUCTURE = 'SET_MAX_STRUCTURE';

export const SET_IS_ACTIVE = 'SET_IS_ACTIVE';
export const SET_NAME = 'SET_NAME';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_SUBCATEGORY = 'SET_SUBCATEGORY';
export const SET_USAGE = 'SET_USAGE';
export const SET_NOTES = 'SET_NOTES';
export const SET_IS_INCLUDED_IN_APR = 'SET_IS_INCLUDED_IN_APR';
export const SET_IS_INCLUDED_IN_POINTS_AND_FEES =
  'SET_IS_INCLUDED_IN_POINTS_AND_FEES';
export const SET_FEE_PAID_BY = 'SET_FEE_PAID_BY';
export const SET_FEE_PAID_TO = 'SET_FEE_PAID_TO';
export const SET_HUD_LINE_NUMBER = 'SET_HUD_LINE_NUMBER';
export const SET_IS_HIDDEN_ADJUSTMENT = 'SET_IS_HIDDEN_ADJUSTMENT';
export const SET_IS_PROFITABILITY_RULE = 'SET_IS_PROFITABILITY_RULE';
export const SET_IS_NEGATIVE_BOOLEAN_EQUATION =
  'SET_IS_NEGATIVE_BOOLEAN_EQUATION';
export const SET_IS_EXCLUDED_FROM_CLAMPING = 'SET_IS_EXCLUDED_FROM_CLAMPING';
export const SET_RULE_FIELDS = 'SET_RULE_FIELDS';
export const SET_ACCURACY = 'SET_ACCURACY';
export const SET_ROUNDING_TYPE = 'SET_ROUNDING_TYPE';
export const SET_RULE_WORKFLOWS_FOR_LOCK_ACTION =
  'SET_RULE_WORKFLOWS_FOR_LOCK_ACTION';
export const SET_TARGET_TYPE = 'SET_TARGET_TYPE';
export const SET_UP_TO_PRICE = 'SET_UP_TO_PRICE';
export const SET_DOWN_TO_PRICE = 'SET_DOWN_TO_PRICE';
export const PAR_ROUNDING_FORM_INVALID = 'PAR_ROUNDING_FORM_INVALID';
export const SET_EXCLUDED_FROM_CLAMPING_TYPE =
  'SET_EXCLUDED_FROM_CLAMPING_TYPE';

export default {
  CLEAR_RULE_DETAILS,
  SET_CLAMP_CATEGORY,
  SET_IS_INVALID,
  SET_IS_INVALID_NAME,
  SET_IS_NEW_ITEM,
  SET_IS_RULE_SAVING,
  SET_IS_UPDATE_NEEDED,
  SET_RULE,
  SET_TARGET,
  SET_UPDATED_GRIDS,
  SET_MIN_STRUCTURE,
  SET_MAX_STRUCTURE,
  SET_NAME,
  SET_IS_ACTIVE,
  SET_CATEGORY,
  SET_SUBCATEGORY,
  SET_USAGE,
  SET_NOTES,
  SET_IS_HIDDEN_ADJUSTMENT,
  SET_IS_PROFITABILITY_RULE,
  SET_IS_NEGATIVE_BOOLEAN_EQUATION,
  SET_IS_EXCLUDED_FROM_CLAMPING,
  SET_RULE_FIELDS,
  SET_ACCURACY,
  SET_ROUNDING_TYPE,
  SET_RULE_WORKFLOWS_FOR_LOCK_ACTION,
  SET_TARGET_TYPE,
  SET_UP_TO_PRICE,
  SET_DOWN_TO_PRICE,
  PAR_ROUNDING_FORM_INVALID,
  SET_EXCLUDED_FROM_CLAMPING_TYPE,
};
